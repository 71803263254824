/* global Component, __lodash__ */
class multiValueFilterComponent extends Component {

    static name() {
        return "multiValueFilterComponent";
    }

    static componentName() {
        return "multiValueFilterComponent";
    }

    getProps() {
        return ['filterField'];
    }

    data() {
        return {
            display:true,
            isMobile: $(window).width() <= 560,
            renderView:false,
            filterToApply:new Map(),
            collections:[],
            inView:0,
            orderBy:'label',
            orderDirection:'asc'
        };
    }

    getWatch() {
        return {
            '$store.state.itemsFullLoader':function (){
                if(this.$store.state.itemsFullLoader) {
                    this.generateFilters();
                }
            },
            '$store.state.itemUpdate': 'calculateResults',
            '$route': 'generateFilters',
            '$store.state.itemTimeFlag':'generateFilters',
        };
    }

    mounted() {
        return async function () {
            let self = this;
            this.generateFilters();
            this.display = !this.isMobile;
            $(window).resize(function () {
                self.isMobile = $(window).width() <= 560;
                self.display = !self.isMobile;
            });
        };
    }

    getMethods() {
        return {
            fetchData:this.fetchData,
            resetCountFilters:this.resetCountFilters,
            calculateResults: this.calculateResults,
            generateFilters:this.generateFilters,
            getfilterLabel:this.getfilterLabel,
            getItemValue:this.getItemValue,
            checkedObserve:this.checkedObserve
        };
    }

    fetchData(filter,value) {
        //console.log('Event Capture',filter,value);
        this.renderView = !this.renderView;
        this.calculateResults();
    }

    resetCountFilters(){
        this.inView = 0;
        for(let filter of this.collections) {
            filter.itemCount = 0;
        }
    }

    calculateResults(){
        //console.log('Calculate Results Multivalue For ',this.filterField.ItemField);
        //console.time('Calculate Result-Multivalue -'+this.filterField.ItemField);
        let items = this.$store.getters.getItemsVisibles; //this.$store.getters.getItems;
        this.resetCountFilters();
        for(let filter of this.collections) {
            for (let x of items.filter((e) => e.visible == true)) {
                try {
                    let isPresent = RegExp(filter.value, 'gi');
                    let isVisible = false;
                    //console.log(counter,x[filter.filterField],filter.label)
                    //counter = counter+1;
                    if (x[filter.filterField] != null) {
                        //console.time(filter.label + 'RexTime' + x.Code);
                        isVisible = isPresent.test(x[filter.filterField]);
                        //console.timeEnd(filter.label + 'RexTime' + x.Code);
                        //console.log(this.filterField.ItemField, isPresent, x[this.filterField.ItemField], isVisible);

                        /*console.time(filter.label+'IndexOfTime-'+x.Code)
                        isVisible = x[this.filterField.ItemField].split(",").indexOf(filter.label);
                        console.time('IndexOfTime'+x.Code)

                        console.time(filter.label+'FunctionOfTime'+x.Code)
                        let tmpFunc2 = new Function("x","return "+cond);
                        isVisible =tmpFunc2(x)==result;
                        console.timeEnd(filter.label+'FunctionOfTime'+x.Code)*/

                        /*console.time(filter.label+'EvalFunctionOfTime'+x.Code)
                        isVisible = (eval(cond) == result)
                        console.timeEnd(filter.label+'EvalFunctionOfTime'+x.Code)-*/
                    }
                    if (isVisible) {
                        filter.itemCount = filter.itemCount + 1;
                        this.inView = this.inView + 1;
                    }
                    filter.active = this.$store.state.filters.indexOf(filter.name) !== -1;
                }
                catch (err) {
                    // eslint-disable-next-line no-console
                    console.log(err, filter, x);
                }
            }
        }
        //console.timeEnd('Calculate Result-Multivalue -'+this.filterField.ItemField);
    }

    generateFilters(){
        let collection = new Map();
        let items = this.$store.getters.getItemsVisibles; ///this.$store.getters.getItems; // Array.from(this.$store.state.items_store.values());
        for(let item of items) {
            let value = this.getItemValue(item,this.filterField.ItemField);
            if (value) {
                let setValues = value.split(",").map(x=>x.trim());
                if(setValues.length > 1) {
                    let labelPos = 0;
                    let setValuesLabels = this.getfilterLabel(item, this.filterField.ItemField).split(",");

                    for (let setValue of setValues) {
                        if (setValue) {
                            if (!collection.has(setValue)) {
                                let filter = {};
                                filter.name = `multivalue-${this.filterField.ItemField}-${setValue}`;  //uuidv4();
                                filter.condition = `x.${this.filterField.ItemField}!= null && x.${this.filterField.ItemField}.split(',').indexOf(\`${setValue}\`)!=-1`;
                                filter.result = true;
                                filter.type = 'multivalue';
                                filter.label = this.capitalize(setValuesLabels[labelPos]);
                                filter.value = setValue;
                                filter.filterField = this.filterField.ItemField;
                                filter.itemCount = 0;
                                filter.hiddens = new Map();
                                filter.active = false;
                                collection.set(setValue, filter);
                            }
                        }
                        labelPos++;
                    }
                }
                else {
                    if (!collection.has(value)) {
                        let filter = {};
                        filter.name = `multivalue-${this.filterField.ItemField}-${value}`;  //uuidv4();
                        filter.condition = `x.${this.filterField.ItemField}!= null && x.${this.filterField.ItemField}.split(',').indexOf(\`${value}\`)!=-1`;
                        filter.result = true;
                        filter.type = 'multivalue';
                        filter.label = this.getfilterLabel(item, this.filterField.ItemField);
                        filter.value = value;
                        filter.filterField = this.filterField.ItemField;
                        filter.itemCount = 0;
                        filter.hiddens = new Map();
                        filter.active = false;
                        collection.set(value, filter);
                    }
                }
            }
        }

        //console.log("Colection",this.filterField.ItemField,collection);
        this.collections = __lodash__.orderBy(Array.from(collection.values()),this.orderBy ,this.orderDirection);
        this.calculateResults();
    }

    getfilterLabel(item,fieldName){
        if(item.fields)
            item = item.fields;
        if(fieldName + '_format' in item)
            if(item[fieldName + '_format'])
                return  item[fieldName + '_format'];
        return item[fieldName];
    }

    getItemValue(item,fieldName){
        if(item.fields)
            item = item.fields;
        return item[fieldName];
    }

    checkedObserve(filter){
        filter.active = true & filter.active;
        this.$store.dispatch('addFilter',filter);
    }

    getTemplate() {
        return `<div class="filter multivalueFilter" v-show="inView>0">
                    <p class="filter-title" @click="display = !display">
                        {{tr(filterField.ItemField)}}
                        <span v-show="display"class="right"><i class="icon fas fa-minus"></i></span>
                        <span v-show="!display"class="right"><i class="icon fas fa-plus"></i></span>
                    </p>
                    <section class="filter-options" :class="{'visible-filter': display}" @click="display = !isMobile">
                        <ul class="unstyled">
                            <template v-for="value of collections">
                                <li :key="'li'+value.name" v-if="value.itemCount>0">
                                    <div class="custom-control custom-checkbox">
                                        <input :key="filterField.ItemField + 'input' +value.name" class="custom-control-input" type="checkbox" v-model="value.active" :id="filterField.ItemField +'_'+value.label" @click="checkedObserve(value)" />
                                        <label :for="filterField.ItemField +'_'+value.label" class="custom-control-label">{{value.label}} ({{value.itemCount}})</label>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </section>
                </div>`;
    }
}

multiValueFilterComponent.registerComponent();